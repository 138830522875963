import React from 'react';
import { FaEye, FaRegHeart } from 'react-icons/fa';
import { RiShoppingCartLine } from 'react-icons/ri';
import Rating from '../Rating';
import { Link } from 'react-router-dom';
import './ProductCard.css';

const ProductCard = ({ product, addCard, addWishlist }) => {
  return (
    <div className="product-card">
      <Link to={`/product/details/${product.slug}`}>
        <div className="product-image-container">
          <img
            className="product-image"
            src={product.images[0]}
            alt={product.name}
          />
        </div>
        <div className="product-info-container">
          <div className="product-info">
            <h2 className="product-name">{product.name}</h2>
            {console.log("product-info >>", product)}
            {/* <span className="product-price">${product.upfront_amount}</span> */}
          </div>
          <div className="rating-container">
            <Rating ratings={product.rating} />
          </div>
          <ul className="action-icons" onClick={(e) => e.stopPropagation()}>
            <li
              onClick={(e) => {
                e.preventDefault();
                addWishlist(product);
              }}
              className="action-icon"
            >
              <FaRegHeart />
            </li>
            <li onClick={(e) => e.preventDefault()} className="action-icon">
              <FaEye />
            </li>
            <li
              onClick={(e) => {
                e.preventDefault();
                addCard(product._id);
              }}
              className="action-icon"
            >
              <RiShoppingCartLine />
            </li>
          </ul>
        </div>
      </Link>
    </div>
  );
};

export default ProductCard;
